module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Uzr.ai | Customer Data & Marketing Platform for D2C brands","description":"description: 5,000+ D2C brands that use uzr.ai's platform to manage, analyze & monetize customers. We are a complete CDP with Data Management, Personalization, AMP Email Marketing, & more!","short_name":"Uzr.ai","start_url":"https://uzr.ai","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/Assets/images/favicon-16x16.png","icons":[{"src":"static/images/icons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"static/images/icons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"static/images/icons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/images/icons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"eeddacb2eef5b78f572a3ed4f43e49f2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4M1JFRYV7R"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
