exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-type-index-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__type}/index.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-type-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-type-markdown-remark-frontmatter-category-index-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__type}/{MarkdownRemark.frontmatter__category}/index.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-type-markdown-remark-frontmatter-category-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-type-markdown-remark-frontmatter-category-markdown-remark-frontmatter-link-index-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__type}/{MarkdownRemark.frontmatter__category}/{MarkdownRemark.frontmatter__link}/index.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-type-markdown-remark-frontmatter-category-markdown-remark-frontmatter-link-index-js" */),
  "component---src-pages-new-file-js": () => import("./../../../src/pages/newFile.js" /* webpackChunkName: "component---src-pages-new-file-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

